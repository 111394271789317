$(() => {
  // remove disabled attr from language switcher
  $('#language-switcher').removeAttr('disabled');

  $('#language-switcher').on('change', (e) => {
    const locale = e.target.value;
    const { pathname } = window.location;
    const { search } = window.location;
    const localeRegex = /locale=[a-z]{2}/;
    const newSearch = localeRegex.test(search)
      ? search.replace(localeRegex, `locale=${locale}`)
      : `${search}${search ? '&' : '?'}locale=${locale}`;

    window.location.href = pathname + newSearch;
  });

  $('#language-switcher').selectpicker();

  // This is a tricky way to add image before the button content
  $('.language-switcher-btn').prepend("<img style='margin-right: 10px;' src='/j/images/international.svg'/>");
});
